import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    taxiId: Number,
    userId: Number,
    deviceId: Number,
    driverSessionId: Number,
    taxiLocationEnabled: Boolean,
    isAuthenticated: Boolean,
  };

  connect() {
    this.sendUnifiedMessage()
    this.sendLegacyMessages()
  }

  sendUnifiedMessage() {
    const messageData = {
      taxiId: this.taxiIdValue,
      userId: this.userIdValue,
      deviceId: this.deviceIdValue,
      driverSessionId: this.driverSessionIdValue,
      taxiLocationEnabled: this.taxiLocationEnabledValue,
      isAuthenticated: this.isAuthenticatedValue
    }

    // Remove undefined properties
    Object.keys(messageData).forEach(key => messageData[key] === undefined && delete messageData[key])

    if (Object.keys(messageData).length > 0) {
      window.TurboNativeBridge.postMessage("driverSessionUpdate", messageData)
    }
  }

  sendLegacyMessages() {
    if (this.taxiIdValue) {
      window.TurboNativeBridge.postMessage("currentTaxi", {"taxiId": this.taxiIdValue})
    }
  }
}
