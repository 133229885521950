import Rails from "@rails/ujs";
import "form-request-submit-polyfill";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "./lib/turbo_native/bridge";
import "./channels";
import "./controllers";
import LocalTime from "local-time";
import ahoy from "ahoy.js";  // eslint-disable-line
import { getMetaValue } from "./helpers";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import "trix"
import "@rails/actiontext"

const sentryConfig = JSON.parse(getMetaValue("sentry"));

Sentry.init({
  ...sentryConfig,
  integrations: [
    new BrowserTracing(
      {
        beforeNavigate: context => {
          return {
            ...context,
            name: location.pathname
              .replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89an][0-9a-f]{3}-[0-9a-f]{12}/g, "/<uuid>")
              .replace(/\/([A-Za-z0-9-_]{11})\//g, "/<unique-id>/")
              .replace(/\/\d+/g, "/<digits>"),
          };
        },
      }
    ),
  ],
});

const userId = getMetaValue("current-user-id");
if (userId) {
  Sentry.setUser({id: userId});
}

Rails.start();
ActiveStorage.start();
LocalTime.start();

import { StreamActions } from "@hotwired/turbo"

StreamActions.move = function() {
  const parent = document.getElementById(this.getAttribute("to"))
  if(parent) {
    // If this.targetElements is not found, we are appending to the parent
    if(this.targetElements.length === 0 && this.templateContent) {
      parent.appendChild(this.templateContent);
      return;
    }

    this.targetElements.forEach(element => {
      // If parent is not the same as the target parent we are removing the element from parent and appending it to the new parent
      if (parent !== element.parentNode) {
        element.remove()
        parent.appendChild(element);
      }

      if(this.templateContent) {
        element.replaceWith(this.templateContent);
      }
    })
  }
}

StreamActions.visit = function() {
  const location = this.getAttribute("location")

  if(location) {
    Turbo.visit(location, { action: "advance" })
  }
}

StreamActions.reload = function() {
  window.location.reload();
}

StreamActions.addClass = function() {
  this.targetElements.forEach(element => {
    element.classList.add(this.getAttribute("add"))
  })
}

const dispatchMapsEvent = (...args) => window.dispatchEvent(new CustomEvent("google-maps-loaded", args));
window.dispatchMapsEvent = dispatchMapsEvent

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false
}

addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]")
  h1Button.insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
    <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
  `)
})
