import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row"];

  static classes = ["active"];

  connect() {
    this.setActiveLink();
    document.addEventListener("turbo:frame-load", () => this.setActiveLink());
  }

  setActiveLink() {
    this.rowTargets.forEach((element) => {
      if (element.href !== window.location.href) {
        element.classList.remove(...this.activeClasses);
      } else {
        element.classList.add(...this.activeClasses);
      }
    });
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", () => this.setActiveLink());
  }
}
