import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sound", "gif", "icon"]
  static values = {
    correct: Boolean
  }

  playPedro(event) {
    event.preventDefault()
    this.gifTarget.classList.remove("!hidden")
    this.iconTarget.classList.add("!hidden")
    try {
      const url = this.soundTarget.src;
      const audio = new Audio(url);
      audio.play();
    } catch (error) {
      console.error("Failed to play sound:", error);
    }
  }
}
