import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["destinationContainer", "footerNextButton"]

  handleHideDestinationTarget() {
    this.destinationContainerTarget.style.display = 'none'
  }

  handleShowDestinationTarget() {
    this.destinationContainerTarget.style.display = 'block'
  }

  handleHideFooterTarget() {
    this.footerNextButtonTarget.style.display = 'none'
  }

  handleShowFooterTarget() {
    this.footerNextButtonTarget.style.display = 'block'
  }
}
