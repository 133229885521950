import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["block"];

  connect() {
    this.blockTargets.forEach((block) => {
      this.stickyElementStyle = window.getComputedStyle(block);
      block.dataset.initialTop = parseInt(this.stickyElementStyle.top, 10) || 0;
    });

    this.determineStickyState();
    window.addEventListener('scroll', this.determineStickyState.bind(this));
  }

  determineStickyState() {
    this.blockTargets.forEach((block) => {
      const initialTop = parseInt(block.dataset.initialTop, 10);
      const currentTop = block.getBoundingClientRect().top;
      block.classList.toggle('is-sticky', currentTop <= initialTop);
    });
  }
}
