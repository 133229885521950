import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon", "wrapper"];

  toggleAccordion() {
    this.iconTarget.classList.toggle("icon-chevron-down");
    this.iconTarget.classList.toggle("icon-chevron-up");
    this.wrapperTarget.classList.toggle("hidden");
  }
}
