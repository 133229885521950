import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  click(event) {
    const link = event.target.closest("a")

    if (link.dataset.processing) {
      event.stopPropagation()
      event.preventDefault()
      return
    }

    link.dataset.processing = true

    setTimeout(() => {
      link.dataset.processing = false
    }, 2000)
  }

  submit(event) {
    const submitButton = event.target

    if (submitButton) {
      submitButton.disabled = true
    }

    event.target.form.requestSubmit(submitButton)
  }
}
