import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { seconds: Number }
  static targets = ["button", "counter"]

  connect() {
    this.remainingTime = this.secondsValue
    this.updateCounter()
    this.updateButton()

    this.interval = setInterval(() => {
      this.remainingTime -= 1
      this.updateCounter()
      this.updateButton()

      if (this.remainingTime <= 0) {
        clearInterval(this.interval)
      }
    }, 1000)
  }

  updateCounter() {
    this.counterTarget.textContent = this.remainingTime
  }

  updateButton() {
    this.buttonTarget.disabled = this.remainingTime > 0
  }
}
