import { Controller } from "@hotwired/stimulus"
import {createConsumer} from "@rails/actioncable";

export default class extends Controller {
  static targets = ["map"];
  static values = {
    url: String,
    taxiId: Number,
    lastPosition: Object
  };

  connect() {
    this.initMap();
    this.subscribeToChannel();
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 12,
      center: this.lastPositionValue,
    });

    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        data.forEach(location => {
          this.addMarker(location);
        });
      });
  }

  addMarker(location) {
    const position = {
      lat: location.latitude,
      lng: location.longitude
    };

    const color = this.getColorBasedOnAccuracy(location.horizontal_accuracy, location.vertical_accuracy);

    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      icon: this.createDotIcon(color),
    });

    const date = new Date(location.created_at).toString();
    // location.speed is in m/s, convert to km/h
    const kmh = location.speed * 3.6;
    const label = `ID: ${location.id}, Time: ${date}, Speed: ${kmh.toFixed(1)} km/h, Course: ${location.course}°, Horizontal Accuracy: ${location.horizontal_accuracy}, Vertical Accuracy: ${location.vertical_accuracy}`;

    const infowindow = new google.maps.InfoWindow({
      content: label
    });

    marker.addListener("click", () => {
      infowindow.open(this.map, marker);
    });
  }

  subscribeToChannel() {
    this.subscription = createConsumer().subscriptions.create(
      {
        channel: "TaxiLocationChannel",
        taxi_id: this.taxiIdValue
      },
      {
        received: this.handleReceived.bind(this)
      }
    );
  }

  handleReceived(data) {
    this.addMarker(data);
    const newLocation = new google.maps.LatLng(data.latitude, data.longitude);
    this.map.panTo(newLocation);
  }

  createDotIcon(color) {
    return {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: color,
      fillOpacity: 1,
      scale: 10,
      strokeColor: color,
      strokeWeight: 2,
    };
  }

  getColorBasedOnAccuracy(horizontalAccuracy, verticalAccuracy) {
    const accuracy = Math.max(horizontalAccuracy, verticalAccuracy);
    return this.getColorFromGradient(accuracy);
  }

  getColorFromGradient(accuracy) {
    if (accuracy === -1) {
      return `rgb(255, 0, 0)`;
    }

    // Map accuracy to a 0-1 scale where 0.0 meters is good (green) and 40.0 meters is bad (red)
    const minAccuracy = 0.0;
    const maxAccuracy = 40.0;
    const clampedAccuracy = Math.max(minAccuracy, Math.min(accuracy, maxAccuracy));
    const value = (clampedAccuracy - minAccuracy) / (maxAccuracy - minAccuracy);

    // Linear gradient from green (0) to red (1)
    const r = Math.floor(255 * value);
    const g = Math.floor(255 * (1 - value));
    return `rgb(${r}, ${g}, 0)`;
  }
}
