import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["calculate-trip-price-preview", "percentage-factor"];

  static targets = ["distance", "duration"];

  update() {
    if (this.hasCalculateTripPricePreviewOutlet) {
      this.calculateTripPricePreviewOutlets.forEach((calculator) => {
        calculator.update(1);
      });
    }
  }

  updatePriceGroup() {
    if (this.hasPercentageFactorOutlet) {
      this.percentageFactorOutlets.forEach((factor) => {
        factor.update();
      });
    }
  }
}
