import { Controller } from "@hotwired/stimulus"
import CoordinateObject from "../../javascript/lib/coordinate_object"
/* global google */

export default class extends Controller {
  static targets = [
    "searchInput",
    "searchContainer",
    "searchBackButton",
    "searchInputContainer",
    "searchButton",
    "houseNumberContainer",
    "houseNumberInput"
  ]

  static values = {
    origin: Object,
    destination: Object,
    maxRadiusMeters: Number,
    countries: Array,
  }

  selectedPlace = ''

  initialize() {
    const { lng, lat } = this.originValue
    this.countries = this.countriesValue
    this.radius = this.maxRadiusMetersValue
    this.origin = new CoordinateObject(lat, lng)
    this.selectedAddress = { name: this.searchInputTarget.value }
    this.checkValue()
  }

  connect() {
    if (typeof (google) != "undefined") {
      this.initAutocomplete()
    }
  }

  get circle() {
    // Create a bounding circle in meters from the center point
    return new google.maps.Circle({ center: this.origin, radius: this.radius })
  }

  initAutocomplete() {
    const options = {
      componentRestrictions: { country: this.countries },
      fields: ["address_components", "geometry", "name", "types"],
      bounds: this.circle.getBounds(),
      strictBounds: true,
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, options)

    // ClickHandler for the button, needed to send the Directions API with it -
    // Does not work with Stimulus click events
    const onClickHandler = async () => {
      // Gets the autocomplete place values
      let place = this.autocomplete.getPlace()
      if (typeof place.address_components === "undefined") {
        const suggestion = await this.queryAutocomplete(place.name)
        if (suggestion) {
          place = suggestion
        }
      }
      this.destinationValue = place?.geometry?.location
      let detail = {
        destination: this.destinationValue
      }
      if (place?.formatted_address) {
        detail = { ...detail, address: place.formatted_address }
      }

      if (place?.types?.includes('route')) {
        this.disableInput()
        this.posthogCapture()
        this.enterHouseNumberContainer()
        this.selectedAddress = place

      } else {
        document.dispatchEvent(new CustomEvent("newDestination", { detail: detail }))
      }
    }

    this.autocomplete.addListener("place_changed", onClickHandler.bind(this))
  }

  hideHouseNumberContainer() {
    this.houseNumberContainerTarget.style.display = 'none'
  }

  enterHouseNumberContainer() {
    this.hideNextButton()
    this.showHouseNumberContainer()
  }

  showHouseNumberContainer() {
    this.hideNextButton()
    this.houseNumberContainerTarget.style.display = 'block'
  }

  disableInput() {
    this.searchInputTarget.disabled = true
  }

  enableInput() {
    this.searchInputTarget.disabled = false
  }

  hideNextButton() {
    const hideTargetsEvent = new CustomEvent("hideTargets")
    document.dispatchEvent(hideTargetsEvent)
  }

  showNextButton() {
    const showTargetsEvent = new CustomEvent("showTargets")
    document.dispatchEvent(showTargetsEvent)
  }

  performHouseNumberSearch(event) {
    event.preventDefault()
    const houseNumber = this.houseNumberInputTarget.value

    if (!houseNumber.trim()) return

    // Combine the house number with the previously selected route address
    const cityComponent = this.selectedAddress.address_components.find(component => component.types.includes("postal_town"));
    const city = cityComponent ? cityComponent.long_name : "";
    const fullAddress = `${houseNumber} ${this.selectedAddress.name}, ${city}`

    // Using the Google Places library to search for the full address
    const request = {
      input: fullAddress,
      componentRestrictions: { country: this.countries },
      fields: ["address_components", "geometry", "name", "types"],
    }

    const service = new google.maps.places.AutocompleteService()
    service.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
        this.getLocationByPlaceId(predictions[0].place_id)
          .then(({ results }) => {
            const lat = results[0].geometry.location.lat()
            const lng = results[0].geometry.location.lng()
            this.enableInput()
            this.showNextButton()
            this.hideHouseNumberContainer()
            document.dispatchEvent(new CustomEvent("newDestination", {
              detail: {
                destination: { lat: lat, lng: lng },
                address: results[0].formatted_address
              }
            }))
          })
      } else {
        document.dispatchEvent(new CustomEvent("invalidDestination", { detail: { destination: null } }))
      }
    })
  }

  houseNumberCancel() {
    this.houseNumberInputTarget.value = ""
    this.enableInput()
    this.hideHouseNumberContainer()
    this.searchInputTarget.focus()
    const valueLength = this.searchInputTarget.value.length
    this.searchInputTarget.setSelectionRange(valueLength, valueLength)
    //this.selectedPlace = ""
  }

  queryAutocomplete(input) {
    const { circle } = this

    const service = new google.maps.places.AutocompleteService(
      this.searchInputTarget,
      {
        componentRestrictions: { country: this.countries },
      },
    )

    return service.getPlacePredictions({ input })
      .then((response) => this.getLocationByPlaceId(response.predictions[0]?.place_id).then(
        (location) => {
          const isLocationWithinCircle = circle
            .getBounds()
            .contains(location.results[0].geometry.location)
          if (isLocationWithinCircle) return location.results[0]
        },
      ).catch((error) => document.dispatchEvent(new CustomEvent("invalidDestination", { detail: { destination: null } }))))
  }

  getLocationByPlaceId(placeId) {
    const geocoder = new google.maps.Geocoder()
    return geocoder.geocode(
      {
        placeId,
      },
      (resp, status) => {
        if (status === "OK") {
          return resp[0]
        }
      },
    )
  }

  checkValue() {
    this.handleHideNextButton()
    const inputValue = this.searchInputTarget.value
    if (inputValue) {
      this.searchInputContainerTarget.classList.add("is-filled")
    } else {
      this.searchInputContainerTarget.classList.remove("is-filled")
    }
  }

  handleHideNextButton() {
    if (!this.searchInputTarget?.value || !this.selectedAddress?.name?.includes(this.searchInputTarget.value)) {
      this.hideNextButton()
      this.selectedAddress = { name: "" }
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ""
    this.houseNumberCancel()
    this.checkValue()
  }

  posthogCapture() {
    const tagEvent = new CustomEvent("posthog:capture", {
      detail: { eventName: "house_number_search" }
    })
    window.dispatchEvent(tagEvent);
  }
}
