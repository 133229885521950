import { Controller } from "@hotwired/stimulus";
/* global google */

export default class extends Controller {
  static values = {
    googleMapsApiKey: String,
    location: Object,
    zoneId: String,
  };

  static targets = ["map"];

  initializeMap() {
    this.location = this.locationValue;

    const { lat, lng } = this.location;
    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat, lng },
      zoom: 10,
      streetViewControl: false,
    });
    this.map.data.loadGeoJson(`/admins/operation_regions/${this.zoneIdValue}/draw_geofences`);
    this.map.data.setStyle((feature) => ({
      fillColor: feature.getProperty("color"),
      strokeWeight: 1
    }));
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }
}
