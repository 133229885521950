import { Controller } from "@hotwired/stimulus";
import posthog from "posthog-js"
import {getMetaValue} from "../helpers";

export default class extends Controller {
  static targets = ["identity"]
  static values = {
    identity: {
      default: [],
      type: Array
    },
    sessionRecordingEnabled: {
      default: false,
      type: Boolean
    }
  }

  initialize() {
    const key = getMetaValue("posthog-api-key")
    if(key) {
      this.posthog = posthog.init(key, {
        api_host: "https://eu.posthog.com",
        disable_session_recording: true,
        session_recording: {
          maskAllInputs: true,
          maskInputFn: (text, element) => {
            if (element?.dataset['record'] === 'true') {
              return text
            }
            return '*'.repeat(text.length)
          },
          maskTextSelector: ":not([data-record='true'])",
        }
      })
    }
  }

  connect() {
    if (!this.posthog) return

    this.originalDistinctId = this.posthog.get_distinct_id()

    if(this.sessionRecordingEnabledValue) {
      this.posthog.startSessionRecording()
    }
  }

  disconnect() {
    this.posthog = null
  }

  capture(event) {
    const eventName = event.detail.eventName
    this.posthog.capture(eventName)
  }

  reset() {
    this.posthog.reset(true)
  }

  identify() {
    if (!this.posthog) return

    if (this.hasIdentityValue && this.identityValue.length !== 0) {
      this.posthog.identify(...this.identityValue)
    }
  }

  identityTargetConnected(target) {
    this.identityValue = JSON.parse(target.dataset["identity"])
  }

  identityValueChanged(value, _) {
    this.identify()
    if(value[0] !== this.originalDistinctId) {
      this.posthog.alias(this.originalDistinctId, value[0])
    }
  }

  sessionRecordingEnabledValueChanged(value, _) {
    if (!this.posthog) return

    if(value) {
      this.posthog.startSessionRecording()
    } else {
      this.posthog.stopSessionRecording()
    }
  }

  startSessionRecording() {
    this.sessionRecordingEnabledValue = true
  }

  stopSessionRecording() {
    this.sessionRecordingEnabledValue = false
  }
}
