import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["driving-details", "percentage-factor"];

  static targets = ["startPrice", "kmPrice", "minutePrice", "minimumPrice", "previewResult"];

  static values = {
    domId: String,
  };

  initialize() {
    this.update();
  }

  update() {
    const startPrice = parseFloat(this.startPriceTarget.value || 0);
    const kmPrice = parseFloat(this.kmPriceTarget.value || 0);
    const minutePrice = parseFloat(this.minutePriceTarget.value || 0);
    const minimumPrice = parseFloat(this.minimumPriceTarget.value || 0);

    const percentFactor = this.hasPercentageFactorOutlet
      ? parseFloat(this.percentageFactorOutlet.factorTarget.value / 100) : 0.00;

    if (this.hasDrivingDetailsOutlet) {
      const drivingDistance = parseFloat(this.drivingDetailsOutlet.distanceTarget.value || 0);
      const drivingDuration = parseFloat(this.drivingDetailsOutlet.durationTarget.value || 0);

      this.previewResultTarget.value = this.calculatePreview(
        startPrice,
        kmPrice,
        minutePrice,
        minimumPrice,
        drivingDistance,
        drivingDuration,
        (1.00 + percentFactor),
      );
    }
  }

  calculatePreview(
    startPrice,
    kmPrice,
    minutePrice,
    minimumPrice,
    drivingDistance,
    drivingDuration,
    percentFactor,
  ) {
    const result = Math.max(
      minimumPrice,
      (startPrice
      + (kmPrice * drivingDistance)
      + (minutePrice * drivingDuration)) * percentFactor,
    );
    return result.toFixed(0);
  }
}
