import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["calculate-trip-price-preview"];

  static targets = ["factor"];

  update() {
    this.calculateTripPricePreviewOutlets.forEach((calculator) => {
      calculator.update();
    });
  }
}
