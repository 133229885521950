import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "debug"

  connect() {
    super.connect()
  }

  displayDebugSheet() {
    this.send("displayDebugSheet", { }, () => {})
  }
}
