import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "toggle"];

  connect() {
    this.drawers = document.getElementsByClassName("offcanvas-drawer");
  }

  toggleOffcanvas(event) {
    if (this.isOffcanvasOpen()) { // Closing the offcanvas should close all drawers
      this.closeDrawer();
    } else {
      this.toggleDrawer(event);
    }
  }

  toggleDrawer(event) {
    const elementToToggle = document.getElementById(event.target.dataset.toggle);

    elementToToggle.classList.toggle("translate-x-0");
    elementToToggle.classList.toggle("translate-x-full");

    this.ensureCloseContainer();
    this.ensureMenuIcon();
  }

  // Open specific drawer
  openDrawer(event) {
    this.toggleDrawer(event);
  }

  closeDrawer() {
    Array.from(this.drawers).forEach((drawer) => {
      drawer.classList.remove("translate-x-0");
      drawer.classList.add("translate-x-full");
    });

    this.toggleTarget.classList.add("icon-equal");
    this.toggleTarget.classList.remove("icon-cancel");

    this.containerTarget.classList.add("hidden");
  }

  ensureCloseContainer() {
    if (!this.isOffcanvasOpen()) {
      this.closeDrawer();
    }

    this.containerTarget.classList.remove("hidden");
  }

  ensureMenuIcon() {
    if (this.isOffcanvasOpen()) {
      this.toggleTarget.classList.remove("icon-equal");
      this.toggleTarget.classList.add("icon-cancel");
    } else {
      this.toggleTarget.classList.add("icon-equal");
      this.toggleTarget.classList.remove("icon-cancel");
    }
  }

  isOffcanvasOpen() {
    return !!Array.from(this.drawers).find((drawer) => drawer.classList.contains("translate-x-0"));
  }
}
