import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["burgerBtn", "nav"];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);

    this.setInitialNavState();
    this.isAboveBreakpoint = window.innerWidth >= 768;

    window.addEventListener("resize", this.handleResize.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    const currentlyAboveBreakpoint = window.innerWidth >= 768;

    if (currentlyAboveBreakpoint !== this.isAboveBreakpoint) {
      this.isAboveBreakpoint = currentlyAboveBreakpoint;

      if (!this.navTarget.classList.contains("is-open")) {
        this.toggleNoTransition(true);
        setTimeout(() => this.toggleNoTransition(false), 50);
      }
    }
  }

  toggleNoTransition(enable) {
    if (enable) {
      this.navTarget.classList.add("no-transition");
    } else {
      this.navTarget.classList.remove("no-transition");
    }
  }

  toggle() {
    this.burgerBtnTarget.classList.toggle("is-open");
    this.navTarget.classList.toggle("is-open");
    this.saveNavState();
  }

  handleClickOutside(event) {
    if (!this.isClickInsideNav(event)) {
      this.closeNav();
    }
  }

  isClickInsideNav(event) {
    return (
      this.navTarget.contains(event.target)
      || this.burgerBtnTarget.contains(event.target)
      || this.isHovering
    );
  }

  openNav() {
    this.navTarget.classList.add("is-open");
    this.saveNavState();
  }

  closeNavIfNotHovered() {
    if (window.matchMedia("(min-width: 768px)").matches && !this.isHovering) {
      this.closeNav();
    }
  }

  setInitialNavState() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      const navState = localStorage.getItem("navState");
      if (navState === "open") {
        this.navTarget.classList.add("is-open");
        this.burgerBtnTarget.classList.add("is-open");

        setTimeout(() => {
          if (!this.isHovering) {
            this.closeNav();
          }
        }, 200);
      }
    }
  }

  saveNavState() {
    const isOpen = this.navTarget.classList.contains("is-open");
    localStorage.setItem("navState", isOpen ? "open" : "closed");
  }

  closeNav() {
    this.navTarget.classList.remove("is-open");
    this.burgerBtnTarget.classList.remove("is-open");
    this.saveNavState();
  }

  get isHovering() {
    return (
      this.navTarget.matches(":hover") || this.burgerBtnTarget.matches(":hover")
    );
  }
}
