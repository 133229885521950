import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slider"]
  static values = { initial: Number }

  connect() {
    this.updateLabelOpacity()
  }

  slideChange() {
    const currentValue = parseInt(this.sliderTarget.value)
    const change = Math.abs(currentValue - this.initialValue)

    if (change > 99) {
      this.sliderTarget.classList.add('is-slided')
      this.element.form.requestSubmit()
    } else {
      this.slideRestart()
    }
  }

  liveUpdate() {
    this.updateLabelOpacity();
  }

  slideRestart() {
    let currentValue = parseInt(this.sliderTarget.value);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      if (currentValue !== this.initialValue) {
        currentValue = this.moveTowardsInitial(currentValue);
        this.sliderTarget.value = currentValue;
        this.updateLabelOpacity();
      } else {
        clearInterval(this.intervalId);
      }
    }, 4)
  }

  moveTowardsInitial(current) {
    return current < this.initialValue ? current + 1 : current - 1
  }

  updateLabelOpacity() {
    const labels = this.sliderTarget.labels;
    const change = Math.abs(this.sliderTarget.value - this.initialValue);
    const opacityValue = 1 - (change / 100);

    labels.forEach(label => label.style.opacity = opacityValue);
  }
}
